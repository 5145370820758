$main-width: 1108px;
$color-word: #3b3e43;
$color-word-sel: #4984f1;
$color-dark: #414141;
$color-light: #aeaeae;
$color-light-a: #b2b2b2;
$color-dark-s: #2b2e31;
$color-dark-a: #2d2d2d;
$color-green: #8de79e;
$color-red: #fe5858;
$header-height: 75px;
