// @import "~bootstrap/scss/functions";
// @import "~bootstrap/scss/variables";
// @import "~bootstrap/scss/mixins";
// @import '~bootstrap/scss/bootstrap-reboot';
@import '~bootstrap/scss/bootstrap';
// @import '~bootstrap/scss/spinners';
// @import '~bootstrap/scss/navbar';
// @import '~bootstrap/scss/nav';

// @import "~bootstrap/scss/functions";
// @import "~bootstrap/scss/variables";
// @import "~bootstrap/scss/mixins";
// @import "~bootstrap/scss/root";
// @import "~bootstrap/scss/reboot";
// @import '~bootstrap/scss/spinners';
// @import '~bootstrap/scss/navbar';
// @import '~bootstrap/scss/nav';
@media (min-width: 576px) {
  html {
    font-size: 12px;
  }
}
@media (min-width: 768px) {
  html {
    font-size: 12px;
  }
}
@media (min-width: 992px) {
  html {
    font-size: 14px;
  }
}

@media (min-width: 1200px) {
  html {
    font-size: 16px;
  }
}

@import '../assets/font/font.css';
@import './predefined.scss';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'SourceHanSansCN';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color-word;
}
p {
  margin-top: 0;
  margin-bottom: 0;
}
.page-parent {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.page-container {
  margin-top: $header-height;
  flex-grow: 1;
}
.container {
  padding-left: 0;
  padding-right: 0;
}
.content-box {
  width: 67%;
  margin: 0 auto;
}
.def-img-bg {
  background-color: #17181a;
}
.def-shadow {
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.3);
}
.c-green {
  color: $color-green;
}
.c-red {
  color: $color-red;
}
.c-blue {
  color: $color-word-sel;
}
.null-tips {
  text-align: center;
  color: #666;
  line-height: 200px;
  height: 200px;
}
.null-parent {
  min-height: 200px;
}
.pull-load {
  flex-grow: 1;
}
.hover-img-box {
  overflow: hidden;
  img {
    width: 100%;
    transition: all 1.5s;
  }
}
.hover-img-box:hover {
  img {
    transform: scale3d(1.1, 1.1, 1);
  }
}

.mobile {
  .container {
    max-width: 100%;
  }
  .page-container {
    margin-top: 1.28rem !important;
  }
  .spinner-grow {
    width: 0.5rem;
    height: 0.5rem;
  }
  .loading-parent {
    min-height: 1rem;
  }
  .null-tips {
    line-height: 0.8rem;
    height: 0.8rem;
    font-size: 0.2rem;
  }
  .null-parent {
    min-height: 0.8rem;
  }
}
