.yx-toast {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  transition: all 0.5s;
  font-size: 13px;
  text-align: center;
}
.loading-toast {
  background-color: rgba(0, 0, 0, 0.1);
}

.toast-body {
  text-align: center;
  position: relative;
  top: 32%;
  transition: all 0.5s;
}

.toast-box {
  background-color: rgba(0, 0, 0, 0.8);
  /*width: 60%;*/
  display: inline-block;
  margin: 0 auto;
  padding: 16px;
  color: #eee;
  border-radius: 5px;
}

.toast-confirm,
.toast-tips {
  background-color: #fff;
  max-width: 50%;
  margin: 0 auto;
  color: #666;
  overflow: hidden;
  border-radius: 5px;
  display: inline-block;
}

.toast-btn {
  float: left;
  width: 50%;
  line-height: 40px;
  border: solid 1px #eee;
  display: block;
  margin: 0 auto;
}

.toast-tips .toast-btn {
  width: 100%;
}

.toast-content {
  padding: 40px;
}

.toast-sure {
  color: #e54e4b;
}

.toast-loading img {
  width: 34px;
}
